<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            :src="logo_bpdp"
            max-height="40"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />
        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
      <base-item
        :item="{
          title: 'v3.1_IT BPDPKS@2020',
          icon: 'mdi-security-home'
        }"
      />
    </template>

  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
  import secureStorage from '@/utils/secureStorage'
  import { getSettingParamEofficeData, updateSettingParam } from '@/api/settingparam'
  import { getActiveUser } from '@/api/admin'
  import { getListUsrPosActiveAll } from '@/api/hruserpositions'
  import { getALLHROrganization } from '@/api/hrorganization'
  import { getListPositionsActive } from '@/api/hrpositions'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      vTimeParam: null, 
      items: [],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
      logo_bpdp () {
        return require('@/assets/logo.png')
      }
    },
    destroyed () {
      clearInterval(this.vTimeParam)
    },
    created () {
      var vm = this
      var ahour = 1 * 1000 * 60 * 60
      vm.vTimeParam = setInterval(function(){
        vm.checkParam()
      }, ahour)
    },
    mounted () {
      var vm = this
      let usrpos = secureStorage.getItem('userpos')
      vm.parseMenu()
      
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      parseMenuChildren (parent, arrChild) {
        var result = []
        const currRole = secureStorage.getItem('currRole')

        if(arrChild){
          arrChild.forEach(croute => {
            if(croute.hidden){
            }else{
              
              if(croute.meta){
                if(croute.meta.roles){
                  
                  var roles = croute.meta.roles
                  if(roles){
                    if (roles.includes(currRole.rolename)) {
                      var child = croute.children
                      var tmp = {title: croute.name, icon: croute.icon, to: '../../../../..'+parent.path + '/' + croute.path}
                      if(child){
                        var ch  = this.parseMenuChildren(croute.children)
                        if(ch.length>0){
                          tmp = Object.assign(tmp, {children: ch})
                        }
                      }
                      result.push(tmp)
                    }
                  }
                }
              }
              
            }
          })
        }
        return result
      },
      parseMenu () {
        var menus = []
        const currRole = secureStorage.getItem('currRole')
        // console.log('parseMenu', currRole.rolename)
        this.$router.options.routes.forEach(route => {
          if(route.hidden){
          }
          else{
            var tmp = {}
            // console.log('route.path',route.path)
            // console.log(route.path === '/')
            if(route.path === '/'){
              var child = route.children
              // console.log('1roles',child)
              if(child){
                child.forEach(childr => {
                  var roles = childr.meta.roles
                  // console.log('roles1', roles)
                  if(roles){
                    if (roles.includes(currRole.rolename)) {
                      tmp = {title: childr.name, icon: childr.icon, to: '/' +childr.path}
                      if(childr.name){
                        menus.push(tmp)
                      }
                    }
                  }
                })
              }
            }
            else{
              tmp = {title: route.name, icon: route.icon, to: '/' +route.path}
              var child = route.children
              if(child){
                var ch = this.parseMenuChildren(route, child)
                if(ch.length>0){
                  tmp = Object.assign(tmp, {children: ch})
                }
              }
              // console.log('tmp2', tmp)
              var roles = route.meta.roles
              // console.log('roles2', roles)
              if(roles){
                if (roles.includes(currRole.rolename)) {
                  if(route.name){
                    menus.push(tmp)
                  }
                }
              }
            }
          }
        })
        // console.log(menus)
        this.items = menus
      },
      checkParam () {
        console.log('checkParam')
        var needUpdates = 0
        getSettingParamEofficeData().then(response => {
          var data = response.data
          if(data){
            needUpdates = data[0] ? data[0].value : 0
            if(needUpdates > 0){

              getALLHROrganization().then(response => {
                secureStorage.setItem('orgall',response.data)
              }).catch(error => {
                console.log(error)
              })

              getListPositionsActive().then(response => {
                secureStorage.setItem('posall',response.data)
              }).catch(error => {
                console.log(error)
              })

              getListUsrPosActiveAll().then(response => {
                // console.log(response.data)
                secureStorage.setItem('userposall',response.data)
              }).catch(error => {
                console.log(error)
              })

              getActiveUser().then(response => {
                secureStorage.setItem('userall',response.data)
              }).catch(error => {
                console.log(error)
              })

              // console.log('update setting back to 0')
              var idParam = data[0].id

              var param = {
                value: needUpdates - 1
              }
              updateSettingParam(param, idParam).then(respupd => {
                // console.log(respupd.data)
              }).catch(error => {
                console.log(error)
              })
            }
          }

        }).catch(error => {
          console.log(error)
        })
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
<style>
  .v-list--nav {
    padding-left: 5px;
    padding-right: 5px;
  }
  .v-list-item-child{
    margin-left: 10px;
  }
</style>